.internet table {
  border-collapse: separate;
  border-spacing: 0px;
  /* border: 1px solid; */
  padding: 0px;
  margin: 10px;
  display: inline-block;
  background-color: transparent;
}

.internet td {
  padding: 5px 10px 0px 10px;
  margin: 0px;
  border: none;
}

.internet th {
  padding: 3px;
  margin: 0px;
  border: 1px solid;
  /* display: inline; */
  /* align-items: center; */
  /* gap: 10%; */
  /* border: solid; */
}

.internet li {
  padding: 0px;
  display: grid;
  grid-template-columns: repeat(2, auto); /* Two buttons per row */
  gap: 5px; /* Space between buttons */
}

.internet ul {
  display: inline-block;
  text-align: left;
  list-style: disc;
  /* padding: 10px;
  margin: 10px; */
}

.internet a {
  color: #0f3455;
}

form {
  display: flex;
  align-items: center;
  background-color: #333333;
  border-radius: 50px;
  padding: 10px;
}

.search input {
  background-color: transparent;
  color: white;
  border: none;
  flex: 1;
  padding: 0px 0px 0px 10px;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

ol {
  /* background-color: #920f0f; */
  /* padding: 10px; */
  /* margin: 1px; */
  list-style: decimal;
  /* display:flexbox; */
  /* align-items:baseline; */
  /* gap: 3rem; */
  display: inline-block;
  /* list-style-position:inside; */
  /* text-align: center; */
}
