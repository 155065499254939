.links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, auto); /* Two buttons per row */
  gap: 5px; /* Space between buttons */
}

.links button {
  padding: 15px 30px;
  width: 180px;
  /* width: 100%; */
  font-size: 18px;
  border-radius: 50px;
  background-color: #0f3455;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: white;
  /* border: none; */
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 2px solid white;
}

.links button:hover {
  background-color: #19578d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.links h2 {
  padding: 2px;
  margin: 0px;
  -webkit-text-stroke: 3px black;
  /* text-align: center; */
}

.links ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-align: center;
}

.links li {
  list-style: none;
}
