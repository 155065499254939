.streaming table {
  padding: 0px;
  margin: 10px;
  display: inline-block;
  border-spacing: 0px;
}

.streaming td {
  border: 1px solid;
  padding: 10px;
  margin: 0px;
}

li {
  /* padding: 0px; */
}

ol {
  /* background-color: #920f0f; */
  /* padding: 10px; */
  /* margin: 1px; */
  list-style: decimal;
  /* display:flexbox; */
  /* align-items:baseline; */
  /* gap: 3rem; */
  display: inline-block;
  /* list-style-position:inside; */
  text-align: left;
}
