* {
  box-sizing: border-box;
}

:root {
  --primary: #1aac83;
  --error: #e7195a;
}

body {
  margin: 0rem 0rem 1rem 0rem;
  font-family: "Ubuntu", sans-serif;
  background-color: #3c709f;
  /* #3c709f */
  color: white;
  height: 100%;
  /* text-align: right; */
}

.section-header {
  background-image: url("~/public/images/menu/home-header.jpg");
  /* background-color: white; */
  background-size: cover; /* Ensure the image covers the entire header */
  background-position: center; /* Center the image within the header */
  color: white; /* Text color */
  padding: 1px;
  /* border-radius: 80px; */
  /* align-items: center; */
  justify-content: center;
  text-shadow: 1px 1px 1px black, -1px 1px 1px black;
  /* height: 100px; */
  /* margin-bottom: 20px; */
}

.container {
  margin: 0rem 0rem 0rem 0rem;
  text-align: center;
  padding-bottom: 0px;
}

footer {
  padding: 0px;
  margin: 0px;
}

p {
  text-align: left;
  padding: 0px 20px 0px 20px;
}

ul {
  padding: 0px 20px 20px 20px;
}

ol {
  text-align: left;
}

table {
  background-color: white;
  color: black;
}

a {
  color: rgb(209, 209, 70);
  font-weight: bold;
  font-style: normal;
}

.collapsible-trigger {
  padding: 15px 30px;
  /* width: 100%; */
  font-size: 18px;
  border-radius: 50px;
  background-color: #0f3455;
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: bold;
  color: white;
  /* border: none; */
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 2px solid white;
}

.collapsible-trigger:hover {
  background-color: #19578d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.collapsible-content {
  /* display: inline-block; */
  background-color: black;
  /* color: red; */
  /* text-align: left; */

  /* Styles applied when opened... */
}

h2 {
  background-color: #7e9ebc; /* Light blue background color */
  color: black; /* Text color */
  padding: 8px 16px; /* Space around the text */
  margin: 16px 0px 16px 0px;
  /* margin-bottom: 16px; */
}

h4 {
  padding: 0px 50px 0px 50px;
  /* text-align: left; */
}

h5 {
  padding: 0px 20px 0px 20px;
  /* text-align: left; */
}
